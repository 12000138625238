import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_URL;

// const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
}

const requests = {
  del: url =>
      superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url, options) =>
      superagent.get(`${API_ROOT}${url}`, options).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
      superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
      superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  from: (url, body) =>
      superagent.post(`${API_ROOT}${url}`, body).type('form').then(res => {
        res.body = JSON.parse(res.text);
        res.body.token = res.header['authorization'];
        return responseBody(res);
      }),
  download: url =>
      superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).responseType('blob'),
  patch: (url, body) =>
      superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  formData: (url, body, files) => {
    const req = superagent.post(`${API_ROOT}${url}`)
    for (const [key, value] of Object.entries(body)) {
      req.field(key, value);
    }
    files.forEach((file) => req.attach(file.name, file.binary))
    return req.set('enctype', 'multipart/form-data').use(tokenPlugin).then(responseBody)
  }
};

const Auth = {
  current: () =>
      requests.get('/protected/user'),
  login: (email, password) =>
      requests.from('/login', {  username: email, password }),
  register: (username, email, password) =>
      requests.post('/users', { user: { username, email, password } }),
  save: user =>
      requests.put('/user', { user }),
  changePassword: body =>
      requests.patch(`/protected/user/password`, body)
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = article => Object.assign({}, article, { slug: undefined })

const PostalService = {
  all: () =>
      requests.get(`/protected/postal/service`),
  getAllForFilter: () =>
      requests.get(`/protected/postal/service/filter`),
  get: id =>
      requests.get(`/protected/postal/service/${id}`),
  setStatus: (id, status) =>
      requests.patch(`/protected/postal/service/${id}?status=${status}`)
};


const PostalRoute = {
  all: (page, pageSize, q) =>
      requests.get(`/protected/postal/routes?page=${page}&pageSize=${pageSize}${q}`),
  get: id =>
      requests.get(`/protected/postal/routes/${id}`),
  searchCities: (name) =>
      requests.get(`/protected/direction?name=${name}`),
  addRoute: (body) => 
      requests.post('/protected/postal/routes/add', body),
  editRoute: (id, status) =>
      requests.patch(`/protected/postal/routes/${id}?status=${status}`),
  uploadFile: (body) =>
      requests.post('/protected/postal/routes/file', body),
  downloadFile: () => 
      requests.download('/protected/postal/routes/file'),
};

const Orders = {
  all: (page, pageSize, q) =>
      requests.get(`/protected/orders?page=${page}&pageSize=${pageSize}${q}`),
  get: id =>
      requests.get(`/protected/order/${id}`),
  getFile: (orderNum, fileType) =>
      requests.download(`/protected/file?orderNum=${orderNum}&fileType=${fileType}`),
  getChildren: id =>
      requests.get(`/protected/order/${id}/children`),
  getExcel: (q) =>
      requests.download(`/protected/report/orders/excel/map?${q}`),
  update: (orderNum, body) =>
      requests.patch(`/protected/order/${orderNum}`, body),
  updateNote: (orderNum, note) =>
      requests.patch(`/protected/order/note/${orderNum}?note=${note}`),
  create: (body, orgId) =>
      requests.post(`/protected/order?orgId=${orgId}`, body),
  getEvents: (orderNum) => 
      requests.get(`/protected/order/${orderNum}/events`)
}

const OrdersStatus = {
  all: () =>
      requests.get(`/protected/nsi/order/status`)
}

const ProductCategory = {
  all: (name='', code='', page=0, pageSize=10) =>
      requests.get(`/protected/tariff/category?page=${page}&pageSize=${pageSize}&name=${name}&code=${code}`),
  add: (body) =>
      requests.post(`/protected/tariff/category`, body),
  addList: (data, page=0, pageSize=10) =>
      requests.post(`/protected/tariff/category/file?page=${page}&pageSize=${pageSize}`, data),
}

const Offers = {
  get: (orderNum) =>
      requests.get(`/protected/offers?orderNum=${orderNum}`),
  change: (offerId, body) =>
      requests.patch(`/protected/offer/${offerId}`, body),
}

const OrderParcel = {
  get: (orderNum) =>
      requests.get(`/protected/parcels?orderNum=${orderNum}`)
}

const TaskQueue = {
  get: (orderNum) =>
      requests.get(`/protected/task_queue?orderNum=${orderNum}`),
  updateTaskQueue: (id, body) =>
      requests.patch(`/protected/task_queue/${id}`, body),
  all: () =>
      requests.get(`/protected/task_queue/type`),
  getById: (id) =>
      requests.get(`/protected/task_queue/${id}`),
  save: (body, files) =>
      requests.formData(`/protected/task_queue`, body, files)
}

const Report = {
  get: (category) =>
      requests.get(`/protected/reports/${category}`),
}

const Kato = {
  search: (name) =>
      requests.get(`/protected/nsi/kato?name=${name}`)
}

const Calculator = {
  calculate: (body, orgId) =>
      requests.post(`/protected/calculator?orgId=${orgId}`, body),
}

const PsOrderNum = {
  add: (orderId, track) =>
    requests.post(`/protected/postal/track?orderId=${orderId}&track=${track}`, null),
  remove: (id) =>
      requests.del(`/protected/postal/track?id=${id}`),
  update: (id, track) =>
      requests.patch(`/protected/postal/track?id=${id}&track=${track}`, null)
}

const StreetAbbr = {
  all: () =>
      requests.get(`/protected/nsi/street_abbr`)
}

const Invoices = {
  get: (orderNum) =>
      requests.get(`/protected/order/${orderNum}/invoices`),
  save: (orderNum, body) =>
      requests.post(`/protected/order/${orderNum}/invoices`, body)
}

const ApiItems = {
  get: () =>
      requests.get(`/protected/payment/items`)
}

const Organizations = {
  all: () =>
    requests.get(`/protected/orgs`),
  clients: () => 
      requests.get('/protected/nsi/client')
}

const Lock = {
    lock: (componentName) =>
        requests.post(`/protected/ui/block`, {componentName}),
    unlock: (componentName) =>
        requests.post(`/protected/ui/unblock`, {componentName}),
}

const SlqUser = {
  all: (realmId, q, page, pageSize) =>
      requests.get(`/protected/slq_users?realmId=${realmId}&q=${q}&page=${page}&pageSize=${pageSize}`),
  get: (guid) =>
      requests.get(`/protected/slq_user/${guid}`),
  getWallet: (guid) =>
      requests.get(`/protected/slq_user/${guid}/wallet`),
  getWalletHistory: (guid, page, pageSize) =>
      requests.get(`/protected/slq_user/${guid}/wallet_history?page=${page}&pageSize=${pageSize}`),
  allOptions: (q) =>
      requests.get(`/protected/slq_user/options?q=${q}`),
  getStatuses: () => 
      requests.get(`/protected/avr/status`),
  getAvrs: (data, itemsPerPage=1, page=0) => 
      requests.get(`/protected/avrs?userId=${data.userId}&itemsPerPage=${itemsPerPage}&page=${page}&status=${data.status}&avrNum=${data.avrNum}`),
  setStatus: (avrId) => 
      requests.post(`/protected/avr/status?avrId=${avrId}`),
}

const Tariff = {
  save: (data) =>
      requests.post(`/protected/tariff`, data),
  get: (userId) =>
      requests.get(`/protected/tariff/${userId}/disable`),
  disable: (userId) =>
      requests.put(`/protected/tariff/${userId}`),
  getTariff: (userId) =>
      requests.get(`/protected/tariff/${userId}`)
}

const CalcTariff = {
  getPostalServices: () =>
      requests.get('/protected/slq/calc/postalServices'),
  getUslugaPs: (psId) =>
      requests.get(`/protected/slq/calc/usluga/ps/${psId}`),
  savePsZone: (data) =>
      requests.post('/protected/slq/calc/psZone', data),
  deletePsZone: (tariffId) =>
      requests.del(`/protected/slq/calc/psZone/${tariffId}`),
  getPsZones: () =>
      requests.get('/protected/slq/calc/psZones'),
  uploadPsZoneKatoList: (tariffId, formDataWithCsvFile) =>
      requests.post(`/protected/slq/calc/upload-file/zone/${tariffId}`, formDataWithCsvFile),
  getPsZoneKatoList: (tariffId) =>
      requests.get(`/protected/slq/calc/psZone/${tariffId}/katoList`),
  getWeightList: (tariffId) =>
      requests.get(`/protected/slq/calc/psZone/${tariffId}/weightList`),
  editWeightList: (tariffId, data) =>
      requests.post(`/protected/slq/calc/psZone/${tariffId}/weight`, data),
  editZoneName: (tariffId, newName) =>
      requests.put(`/protected/slq/calc/psZone/${tariffId}/changeName?name=${newName}`, null),
  addNewKato: (tariffId, data) =>
      requests.post(`/protected/slq/calc/zoneKato/zone/${tariffId}`, data),
  deactivateKato: (tariffId, data) =>
      requests.post(`/protected/slq/calc/deactivate-zoneKato/zone/${tariffId}`, data),
  getTerminalAwayList: (code) =>
      requests.get(`/protected/slq/calc/take-away-from-terminal/?code=${code}`),
  editTerminalAwayList: (data) =>
      requests.post(`/protected/slq/calc/take-away-from-terminal`, data),
  getDeductionTTList: (code) =>
      requests.get(`/protected/slq/calc/take-away-terminals/?code=${code}`),
  editDeductionTTList: (data) =>
      requests.post(`/protected/slq/calc/take-away-terminals`, data),
}

const Terminals = {
    searchCity: (name) =>
        requests.get(`/protected/terminal/direction?name=${name}`),
    getTerminals: (id, kato, page = 0, pageSize = 10) =>
        requests.get(`/protected/terminal?id=${id}&kato=${kato}&page=${page}&pageSize=${pageSize}`),
    addTerminal: (data) => 
        requests.post(`/protected/terminal`, data),
    deleteTerminal: (id) => 
        requests.post(`/protected/terminal?id=${id}`),
    updateTerminal: (id, data) => 
        requests.post(`/protected/terminal?id=${id}`, data),
}

const Reports = {
    getStatus: () => 
        requests.get('/protected/report/async/orders/excel/map'),
    getProduceStatus: () => 
        requests.get('/protected/report/async/orders/excel/pending'),
    createReport: (params) => 
        requests.post(`/protected/report/async/orders/excel/map?${params}`),
    downloadReport: () => 
        requests.download('/protected/report/async/orders/excel/file'),
}

const StatusesMonitors = {
    getTimelineStatusses: () =>
        requests.get('/protected/timeline/status'),
    getAdminStatusses: () =>
        requests.get('/protected/timeline/status_admin'),
    getOrdersList: ({status, statusAdmin, ps}, page, pageSize) =>
        requests.get('/protected/timeline', {
            page,
            pageSize,
            status,
            statusAdmin,
            ps,
        }),
    setTimelineOrder: (body) =>
        requests.post('/protected/timeline', body),
    getTimelineByOrder: (orderNum) =>
        requests.get(`/protected/order/${orderNum}/timeline`)
}

export default {
  Reports,
  Auth,
  PostalService,
  PostalRoute,
  Orders,
  OrdersStatus,
  ProductCategory,
  Offers,
  OrderParcel,
  TaskQueue,
  Report,
  Kato,
  Calculator,
  PsOrderNum,
  StreetAbbr,
  Invoices,
  ApiItems,
  Organizations,
  Lock,
  SlqUser,
  Tariff,
  Terminals,
  CalcTariff,
  StatusesMonitors,
  setToken: _token => { token = _token; }
};
